import { useContext, useEffect, useState } from "react";

import { AiFillHome, AiFillPieChart } from "react-icons/ai";
import { FaUsers, FaBuilding, FaWifi } from "react-icons/fa";
import { TfiAngleLeft } from "react-icons/tfi";
import { BsFillAlarmFill } from "react-icons/bs";
import { IoLogOutSharp, IoSettings } from "react-icons/io5";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FaBookOpenReader } from "react-icons/fa6";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { DasboardContext } from "../../context/dashboard-context";

import { Link, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

import logo from "../../assets/images/logo.png";
import "./style.css";

const Sidebar = () => {
  const { sidebarOpen, setSidebarOpen, setUserInfo } =
    useContext(DasboardContext);

  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);

  const logOut = () => {
    Cookies.remove("token");
    // fetch("https://app.vivasmartcare.com/api/logout", {
    //   credentials: "include",
    //   method: "POST",
    // });
    setUserInfo(null);
    setRedirect(true);

    localStorage.setItem("hasRefreshed", "false");
  };

  if (redirect) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className={`sidebar-wrapper ${sidebarOpen ? "" : "close"}`}>
      <div className="logo-wrapper">
        <img src={logo} alt="logo" />
        <TfiAngleLeft onClick={() => setSidebarOpen(true)} />
      </div>
      <div className="menu-wrapper">
        <div className="side-menu">
          <AiFillHome />
          <Link to={"/homepage"}>Ana Ekran</Link>
        </div>
        <div className="side-menu">
          <FaUsers />
          <Link to={"/user-list"}>Kullanıcılar</Link>
        </div>
        <div className="side-menu">
          <FaWifi />
          <Link to={"/devices"}>Cihazlar</Link>
        </div>
        <div className="side-menu">
          <BsFillAlarmFill />
          <Link to={"/alarms"}>Alarm</Link>
        </div>
        <div className="side-menu">
          <IoSettings />
          <a href="/login-detail">Ayarlar</a>
        </div>
        <div className="side-menu">
          <FaBuilding />
          <Link to={"/organisation"}>Organizasyon</Link>
        </div>
        <div className="side-menu">
          <IoLogOutSharp />
          <p onClick={logOut}>Çıkış Yap</p>
        </div>
        <div className="side-menu" onClick={() => setShow(true)}>
          <BiSolidHelpCircle />
          <a href="#">Yardım</a>
        </div>
        <div className="side-menu">
          <FaBookOpenReader />
          <a href="#">Kılavuz</a>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Arayüz Hakkında Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
